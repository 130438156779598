import React, { useState, useEffect } from 'react';
import './Home.css';
import Post from '../postComponents/Post';
import axios from 'axios'; // Import axios for API requests
import { toast, ToastContainer } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for react-toastify

function Home() {
  const [posts, setPosts] = useState([]); // State to store posts
  const [loading, setLoading] = useState(false); // State to handle loading state
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [hasMore, setHasMore] = useState(true); // State to check if more posts are available

  const fetchPosts = async () => {
    setLoading(true);

    try {
      // Make API request to get posts and user interactions
      const response = await axios.post('https://crowdbuzz.in/api/getPostsNoPagination.php', {
        userId: '12884', // Replace with actual user ID
      });

      if (response.data.status === 'success') {
        const newPosts = response.data.posts;

        // Update posts and handle pagination
        setPosts((prevPosts) => [...prevPosts, ...newPosts]); // Append new posts
        setHasMore(newPosts.length > 0); // Stop if no new posts are returned
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Error details:', error.response);
      toast.error('An error occurred while fetching posts.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch initial posts when the component mounts
    fetchPosts(currentPage);
  }, []); // Run once on mount

  const loadMorePosts = () => {
    // Increment the page number and fetch posts
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    fetchPosts(nextPage);
  };

  return (
    <div className="home-container">
      <div className="home-top-nav">
        <span className="home-nav-title">Home</span>
      </div>
      <div className="home-content">
        {posts.map((post, index) => (
          <Post key={index} post={post} />
        ))}
        {loading && <p>Loading...</p>} {/* Show while loading */}
        {hasMore && !loading && (
          <button className="load-more-button" onClick={loadMorePosts}>
            Load More
          </button>
        )} {/* Show Load More button if more posts are available */}
        {!hasMore && <p>No more posts available.</p>} {/* Show if no more posts */}
      </div>
      <ToastContainer autoClose={4000} position="top-center" /> {/* Toast notifications */}
    </div>
  );
}

export default Home;
