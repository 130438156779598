import React, { useState, useEffect } from 'react';
import './Post.css';
import { BiComment, BiDislike, BiLike } from 'react-icons/bi';
import axios from 'axios';
import Comments from '../postComponents/Comments';

function Post({ post }) {
  const [likes, setLikes] = useState(post.likes || 0);
  const [dislikes, setDislikes] = useState(post.dislikes || 0);
  const [comments, setComments] = useState([]);
  const [showComments, setShowComments] = useState(false);
  const [loadingComments, setLoadingComments] = useState(false);
  const [userId, setUserId] = useState(null);

  // Extract userId from localStorage on component mount
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUserId(parsedUser.id);
    }
  }, []);

  const handleLike = async () => {
    if (!userId) return alert('User not logged in!');
    

    try {
      const response = await axios.post('https://crowdbuzz.in/api/likeAndUnlike.php', {
        userid: userId,
        postid: post.postId,
        blockType: 'like',
        userId: userId,
      });
      if (response.data.status === 'success') {
        setLikes((prevLikes) => prevLikes + 1);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error('Error liking the post:', error.message);
    }
  };

  const handleDislike = async () => {
    if (!userId) return alert('User not logged in!');
    try {
      const response = await axios.post('https://crowdbuzz.in/api/likeAndUnlike.php', {
        userid: userId,
        postid: post.postId,
        blockType: 'dislike',
        userId: userId,
      });
      if (response.data.status === 'success') {
        setDislikes((prevDislikes) => prevDislikes + 1);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error('Error disliking the post:', error.message);
    }
  };

  const handleCommentClick = async () => {
    if (showComments) {
      setShowComments(false);
      return;
    }

    setLoadingComments(true);
    try {
      const response = await axios.post('https://crowdbuzz.in/api/getComments.php', {
        postid: post.postId,
        userid: userId,
        mainorsub: 'main',
      });

      if (response.data.status === 'success') {
        setComments(response.data.comments);
        setShowComments(true);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error('Error fetching comments:', error.message);
    } finally {
      setLoadingComments(false);
    }
  };

  return (
    <div className="Post" style={{ marginBottom: '80px' }}>
      <div className="post-link">
        <div className="post-card">
          <div className="user-info">
            <div className="user-details">
              <img
                src={post.emojiposturl || 'default-profile.png'}
                alt="Profile"
                className="profile-image"
              />
              <div>
                <h3>{post.username || 'Unknown'}</h3>
                <div className="time">{post.time}</div>
              </div>
            </div>
            <div className="options-menu">
              <i className="fas fa-ellipsis-h"></i>
            </div>
          </div>
          <div className="post-text">{post.postContent}</div>
          {post.postImage && (
            <img
              src={`${post.postImage}`}
              alt="Post"
              className="post-image"
            />
          )}
          <div className="post-actions">
            <span onClick={handleLike} style={{ cursor: 'pointer' }}>
              <BiLike className="action-icon" />
              {likes}
            </span>
            <span onClick={handleDislike} style={{ cursor: 'pointer' }}>
              <BiDislike className="action-icon" />
              {dislikes}
            </span>
            <span onClick={handleCommentClick} style={{ cursor: 'pointer' }}>
              <BiComment className="action-icon" />
              {post.comments}
            </span>
            <span>
              <i className="far fa-share-square"></i>
              Share
            </span>
          </div>
        </div>
      </div>
      {showComments &&
        comments.map((comment, index) => (
          <Comments
            key={index}
            profileimage={comment.imageurl}
            userName={comment.username}
            commentText={comment.comments}
            likeCount={comment.subLikedCount}
            dislikeCount={comment.subUnlikedCount}
            commentCount={comment.commentCount}
          />
        ))}
    </div>
  );
}

export default Post;
