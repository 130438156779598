import React, { useEffect, useState } from 'react';
import './Profile.css';

export default function Profile() {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    try {
      // Retrieve and parse user data from localStorage
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        setUserData(JSON.parse(storedUser));
      } else {
        console.warn('No user data found in localStorage.');
      }
    } catch (error) {
      console.error('Error parsing user data from localStorage:', error);
    }
  }, []);

  const handleSettingsClick = () => {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.crowdbuzztechnologies.crowdbuzz&hl=en_IN&gl=US';
  };

  if (!userData) {
    return <p>Loading...</p>; // Display while data is being retrieved
  }

  return (
    <div className='profile-container'>
      <div className="top-nav">
        <div className="nav-title">Profile</div>
        <button className="settings-button" onClick={handleSettingsClick}>
          <i className="fas fa-cog"></i>
        </button>
      </div>
      <div className="profile">
        <div className="profile-pic">
          <img
            src={userData.profileimage || 'https://via.placeholder.com/150'} // Default image if profileimage is null
            alt="Profile Picture"
          />
        </div>
        <div className="profile-info">
          <h1 className="username">{userData.name || 'Guest User'}</h1>
          <p className="handle">@{userData.email || 'N/A'}</p>
          <p className="bio">{userData.userbio || 'No bio available.'}</p>
          <div className="counts">
            <span className="points">
              {userData.points || 0} <span className="label">Points</span>
            </span>
            <span>
              {userData.followers || 0} <span className="label">Followers</span>
            </span>
            <span>
              {userData.following || 0} <span className="label">Following</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
